import React from "react"
import Seo from "./seo"

export const PageMeta = ({
  shareGraphic,
  metaDesc,
  metaTitle,
  metaKeywords,
  robots,
}) => {
  return (
    <Seo
      title={metaTitle}
      description={metaDesc}
      keywords={metaKeywords}
      metaRobots={robots}
      image={shareGraphic}
    />
  )
}
